import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SplitPaneStateService } from './service/split-pane-state.service';
import { timer } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  navigasi: any;
  halAktif = 'Sirami Kebunku';
  showSplash = true;
  tahun = (new Date()).getFullYear();

  constructor(
    private platform: Platform,
    //private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private splitPaneState: SplitPaneStateService
  ) {
    //this.splashScreen.show();
    this.menuSamping();
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      timer(3000).subscribe(() => this.showSplash = false);
      //this.splashScreen.hide();
    });
  }

  menuSamping() {
    this.navigasi = [
      {
        title: 'Sirami Kebunku',
        url: '.',
        icon: 'home'
      },
      {
        title: 'Tanaman di Lapang',
        url: '/infras',
        icon: 'git-branch'
      },
      {
        title: 'Perencanaan Peremajaan',
        url: '/replant',
        icon: 'refresh-circle'
      },
      {
        title: 'Perencanaan Tanam',
        url: '/seedling',
        icon: 'leaf'
      },
      {
        title: 'Unduh Data',
        url: '/rekap',
        icon: 'download'
      },
      {
        title: 'Kontak',
        url: '/kontak',
        icon: 'chatbubbles'
      }
    ];
  }

  setHal(p: string) {
    this.halAktif = p;
  }

  isHalAktif(p: string) {
    if (p === this.halAktif) {
      return 'warning';
    } else {
      return 'white';
    }
  }

}
